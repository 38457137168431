import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  api_multipague_esteira,
  api_multipague_transacao,
  api_multipague_transacaoPaged,
} from '../../../services/api';
import { maskCpfCnpj } from '../../../util/mask';
import ErrorIcon from '@material-ui/icons/Error';
import { Cancel, HistoryOutlined } from '@material-ui/icons';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { DataGridPage } from '../../../components/DataGrid/DataGridPaginationServer';
import RenderActionsCell from '../../../components/DataGrid/DataGridPaginationServer/Actions';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';
import useDataGridFilters from '../useDataGridFilters';

const ListaTransferencia = () => {
  const {
    filterInputs,
    nomeFavorecido,
    docBeneficiario,
    situacao,
    dateState,
    rowsState,
    setRowsState,
  } = useDataGridFilters();

  const [rowCount, setRowCount] = useState();
  const history = useHistory();
  const { contaPadrao } = useSelector(state => state.contas);
  const [rowsTable, setRowsTable] = useState([]);
  const [textNoRows, setTextNoRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [loadingHistorico, setLoadingHistorico] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [openMenuActions, setOpenMenuActions] = useState(false);

  const columnsTable = [
    { field: 'documento', headerName: 'CNPJ/CPF', width: 140 },
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 150 },
    {
      field: 'banco',
      headerName: 'Banco',
      type: 'string',
      width: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'agencia',
      headerName: 'Agencia',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataCadastros1',
      headerName: 'Data Cadastro',
      type: 'string',
      width: 130,
    },
    {
      field: 'dataAgendamento1',
      headerName: 'Data Pagamento',
      type: 'string',
      width: 130,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      width: 130,
      renderCell: event => (
        <>
          {event.row.status == 'REJEITADO' ? (
            <>
              <p>{event.row.status}</p>
              {event.row?.transferenciaErro != null ? (
                <Tooltip title={event.row?.transferenciaErro?.mensagemErro}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              ) : (
                <Tooltip title={'Houve um erro!'}>
                  <ErrorIcon color="error" style={{ marginLeft: '5px' }} />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <p>{event.row.status}</p>
            </>
          )}
        </>
      ),
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 130,
      renderCell: event =>
        event.row.valor.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      width: 100,
      align: 'center',
      renderCell: event => {
        return (
          <RenderActionsCell
            event={event}
            actionsComponent={
              <div>
                <Tooltip title="Histórico de aprovações">
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {loadingHistorico && event.row.id === rowId ? (
                      <CircularProgress size={24} />
                    ) : (
                      <HistoryOutlined
                        onClick={() => handleHistorico(event.row.id)}
                        color="primary"
                        size={24}
                      />
                    )}
                  </button>
                </Tooltip>
                {event.row.situacao === 'PENDENTE' ? (
                  <Tooltip title="Cancelar">
                    <button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {loadingCancel && event.row.id === rowId ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Cancel
                          onClick={() => handleCancel(event.row)}
                          color="primary"
                          size={24}
                        />
                      )}
                    </button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Cancelamento não permitido">
                    <button
                      disabled
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Cancel color="disabled" size={24} />
                    </button>
                  </Tooltip>
                )}
              </div>
            }
            openMenuActions={openMenuActions}
            setOpenMenuActions={setOpenMenuActions}
            rowId={rowId}
          />
        );
      },
    },
  ];

  const handlePageSize = pageSize => {
    setRowsState({ page: 0, pageSize: pageSize });
  };

  const handleCancel = value => {
    setRowId(value.id);
    const idTransferencia = value.id;
    const cancelCliente = value?.clienteConta?.cliente?.id;
    setLoadingCancel(true);
    api_multipague_transacao
      .put(
        `/transferencia/${idTransferencia}/cancelar?cliente=${cancelCliente}`,
      )
      .then(response => {
        toastComponentSuccess('Evento cancelado com sucesso!');
        setLoadingCancel(false);
        setRowsState({ page: rowsState.page, pageSize: rowsState.pageSize });
      })
      .catch(error => {
        setLoadingCancel(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem);
        }
      });
  };

  const handleHistorico = idTransacao => {
    setRowId(idTransacao);
    setLoadingHistorico(true);
    api_multipague_esteira
      .get(`/administracao/transferencia?codigoTransferencia=${idTransacao}`)
      .then(response => {
        setLoadingHistorico(false);
        history.push('/historico-pix', {
          historic: response.data,
        });
      })
      .catch(err => {
        setLoadingHistorico(false);
        toastComponentError(err.response?.data?.mensagem);
      });
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
  };

  useEffect(() => {
    setLoadingTable(true);
    api_multipague_transacaoPaged
      .get(
        `/transferencia?page=${rowsState.page}&size=${
          rowsState.pageSize
        }&clienteConta=${contaPadrao.id}&dataCadastroInicial=${
          dateState.dataDe
        }&dataCadastroFinal=${dateState.dataAte}&dataPagamentoInicial=${
          dateState.dataPagDe
        }&dataPagamentoFinal=${
          dateState.dataPagAte
        }&status=${situacao}&nomeFavorecido=${nomeFavorecido}&documentoFavorecido=${docBeneficiario.replace(
          /[^\d]/g,
          '',
        )}&modalidade=PIX`,
      )
      .then(response => {
        setLoadingTable(false);
        const qtdItems = response.data.totalElements;
        const formattedData = response.data.content.map(dados => ({
          id: uuidv4(),
          documento:
            dados.favorecido != null
              ? maskCpfCnpj(dados.favorecido?.cnpjCpf)
              : 'Sem informações',
          nome:
            dados.favorecido != null
              ? dados.favorecido.nome
              : 'Sem informações',
          situacao: dados.status != null ? dados.status : 'Sem informações',
          dataAgendamento1: dados.dataAgendamento,
          dataCadastros1: moment(dados.dataCadastro).format('DD/MM/YYYY'),
          agencia:
            dados.clienteConta != null
              ? dados.clienteConta.agencia
              : 'Sem informações',
          banco:
            dados.clienteConta != null
              ? dados.clienteConta.banco.nome
              : 'Sem informações',
          valor: dados.valor.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          ...dados,
        }));
        setRowCount(qtdItems);
        setRowsTable(formattedData);

        if (formattedData.length == 0) {
          setTextNoRows('Nenhuma transferência encontrada');
        }
      })
      .catch(err => {
        setTextNoRows(
          err.response.status === 404
            ? 'Nenhuma transferência encontrada'
            : null,
        );
        setLoadingTable(false);
        setRowsTable([]);
        setRowCount(0);
        console.log(err);
      });
  }, [contaPadrao, rowsState]);

  return (
    <DataGridPage
      title={'Lista de PIX'}
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Transferência' },
      ]}
      newRegister={() => history.push('/TransferenciaPIX')}
      formData={filterInputs}
      rows={rowsTable}
      key={rowsTable.key}
      rowCount={rowCount}
      setDatagrid={setRowsState}
      columns={columnsTable}
      loading={loadingTable}
      minHeight={'600px'}
      pagination
      {...rowsState}
      paginationMode="server"
      onPageChange={onPage}
      componentsProps={{
        pagination: { classes: null },
      }}
      onRowClick={params => setRowId(params.id)}
      textNoRows={textNoRows}
      rowsPerPageOptions={[10, 20, 50]}
      onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
    />
  );
};

export default ListaTransferencia;
