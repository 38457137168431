import React from 'react';
import loader from '../../assets/loader_original.gif';

const sizes = {
  fullHeight: '100%',
  table: '1.41rem',
  small: '4.05rem',
  medium: '6.75rem',
  large: '8.rem',
};

export const Loader = ({ size = 'medium', fullHeight }) => {
  const key = fullHeight ? 'fullHeight' : size;
  return (
    <img
      style={{ height: sizes[key], maxHeight: '145px', maxWidth: '350px' }}
      src={loader}
    />
  );
};

export default Loader;
