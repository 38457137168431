import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CircularProgress, Grid, TextField, useTheme } from '@material-ui/core';

import Switch from '@material-ui/core/Switch';
import {
  api_multipague_configuracao,
  api_multipague_conta,
  api_multipague_transacao,
} from '../../services/api';
import { listarBancosRequest } from '../../store/modules/bancos/actions';
import getValidationErros from '../../util/FormattedError';
import validarCpfCnpj from '../../util/vlidarCpfCnpj';
import * as Yup from 'yup';
import {
  hideLoading,
  hideLoadingButton,
  showLoading,
} from '../../store/modules/loading/actions';
import Buttons from '../Buttons/ConfirmButton';
import InputText from '../InputText';
import CPF from '../Inputs/CPF';
import CNPJ from '../Inputs/CNPJ';
import InputMask from '../Inputs/MaskPersonalizavel';
import RadioGroup from '../RadioButton';
import SelectComponent from '../Select';
import AutoCompleteUnform from '../AutoCompleteUnform';

import { MdSave, MdArrowBack, MdSearch } from 'react-icons/md';

import Wrapper from '../Wrapper';
import Box from '../Box';

import {
  Container,
  ContainerButtons,
  ContainerRadioGroup,
  ContainerTitle,
  FormContainer,
  Title,
} from './styles';
import { toastComponentError, toastComponentSuccess } from '../Toast';
import {
  enunsChavePixFavorecido,
  INPUT_CHAVEPIX_TIPO,
  optionsTypeObject,
  TIPO_CHAVEPIX,
  tipoChavePix,
  tipoContas,
} from './objects';
import { initialStateSearchKey } from '../../pages/PIX/constants';
import { Autocomplete } from '@material-ui/lab';
import ReactInputMask from 'react-input-mask';

const NewBeneficiary = ({ callback }) => {
  const { clienteId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const refSubmit = useRef(null);
  const defaultAccount = useSelector(state => state.contas);
  const listBanks = useSelector(state => state.bancos);
  const { userAccount } = useSelector(state => state.user);
  const [isEditting, setIsEditting] = useState(false);
  const [loading, setLoading] = useState(false);
  const verifyMaster = localStorage.getItem('roles');
  const isMaster = verifyMaster?.includes('usuario_master');

  const [optionsTypePerson, setOptionsTypePerson] = useState(optionsTypeObject);

  const [typePersonSelected, setTypePersonSelected] = useState(
    optionsTypePerson[0].selected
      ? optionsTypePerson[0].value
      : optionsTypePerson[1].value,
  );
  const [bancos, setBancos] = useState([]);
  const [tipoChavePixSelected, setTipoChavePixSelected] = useState();
  const [initialDataState, setInitialDataState] = useState([]);
  const [userActive, setUserActive] = useState(true);
  const [cnpjCpfConsultar, setCnpjCpfConsultar] = useState({
    cpf: '',
    cnpj: '',
  });
  const [cpfPessoaFisica, setCpfPessoaFisica] = useState('');
  const [cpfPessoaJuridica, setCpfPessoaJuridica] = useState('');
  const [bneficiarioNome, setBeneficiarioNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [getKey, setGetKey] = useState(initialStateSearchKey);
  const [carregandoCpf, setCarregandoCpf] = useState({
    cpf: false,
    cnpj: false,
  });
  const [typeKeySelected, setTypeKeysSelected] = React.useState();
  const [valueKey, setValueKey] = useState();

  useEffect(() => {
    if (cnpjCpfConsultar.cpf?.length === 14) {
      setCarregandoCpf({ cpf: true });
      api_multipague_configuracao
        .get(
          `/pessoa-fisica/consultarCpf/${cnpjCpfConsultar.cpf.replace(
            /[^0-9]+/g,
            '',
          )}`,
        )
        .then(response => {
          setBeneficiarioNome(response.data.nome);
          setCarregandoCpf({ cpf: false });
        })
        .catch(function (error) {
          setCarregandoCpf({ cpf: false });
          setBeneficiarioNome('');
          toastComponentError(`${error?.response?.data?.mensagem}`);
        });
    } else if (cnpjCpfConsultar.cnpj?.length === 18) {
      setCarregandoCpf({ cnpj: true });
      api_multipague_configuracao
        .get(
          `/empresa/consultarCnpj/${cnpjCpfConsultar.cnpj.replace(
            /[^0-9]+/g,
            '',
          )}`,
        )
        .then(response => {
          setEmpresa(response.data.nome);
          setCarregandoCpf({ cnpj: false });
        })
        .catch(function (error) {
          setCarregandoCpf({ cnpj: false });
          setEmpresa('');
          toastComponentError(`${error?.response?.data?.mensagem}`);
        });
    }
  }, [cnpjCpfConsultar]);

  useEffect(() => {
    dispatch(listarBancosRequest());
  }, [dispatch]);

  useEffect(() => {
    const banksFormatted = listBanks.map(data => ({
      label: `${data.id} - ${data.nome}`,
      value: data.id,
      ...data,
    }));
    setBancos(banksFormatted);
  }, [listBanks]);

  useEffect(() => {
    if (callback) {
      callback(refSubmit);
    }
  }, [refSubmit]);

  useEffect(() => {
    if (!!clienteId) {
      setIsEditting(true);
      if (userAccount?.id) {
        findFavorecido();
      }
    }
  }, [userAccount.id]);

  async function findFavorecido() {
    try {
      const { data } = await api_multipague_conta.get(
        `/favorecido?idCliente=${userAccount.id}&idClienteConta=${
          defaultAccount.contaPadrao.id
        }&cnpjCpf=${''}&nome=${''}&chavePix=${''}&idFavorecido=${
          clienteId ? Number(clienteId) : ''
        }&aprovados=false`,
      );

      if (!!data.length) {
        const initiaDate = {
          cnpjCpf: data[0].cnpjCpf || '',
          agencia: data[0].agencia || '',
          conta: data[0].conta || '',
          digitoConta: data[0].digitoConta,
          nrBanco: data[0].nrBanco || '',
          nome: data[0].nome || '',
          tipoConta: data[0].tipoConta || '',
          tipoPessoa: String(data[0].tipoPessoa),
          tipoChavePix: data[0].tipoChavePix || '',
          chavePix: data[0].chavePix || '',
        };
        setTipoChavePixSelected(data[0].tipoChavePix?.toUpperCase());
        setUserActive(data[0].ativo);
        const formattedPerson = optionsTypePerson.map(tp => ({
          ...tp,
          selected:
            data[0].tipoPessoa === 1 && tp.value === 'PF'
              ? true
              : data[0].tipoPessoa === 2 && tp.value === 'PJ'
              ? true
              : false,
        }));

        if (data[0].tipoPessoa === 1) {
          setTypePersonSelected('PF');
        } else {
          setTypePersonSelected('PJ');
        }

        setOptionsTypePerson(formattedPerson);
        setInitialDataState([...initialDataState, initiaDate]);
      }
    } catch (err) {
      console.log('err:', err);
      toastComponentError(
        ` Codigo: ${err?.response?.data?.codigo} - ${err?.response?.data?.mensagem}`,
      );
    }
  }

  const submitInputs = useCallback(
    async infoForm => {
      try {
        dispatch(showLoading(true));
        const schmeCadastro = !!getKey.sucesso
          ? Yup.object().shape({
              cnpjCpf: Yup.string().required('CPF/CNPJ é obrigatorio'),
              nome: Yup.string().required('Nome é obrigatorio'),
              tipoConta: Yup.string().required('Selecione o tipo de conta'),
            })
          : Yup.object().shape({
              cnpjCpf: Yup.string().required('CPF/CNPJ é obrigatorio'),
              agencia: Yup.string().required('Agencia é obrigatoria'),
              conta: Yup.string().required('Conta é obrigatoria'),
              digitoConta: Yup.string().required('Dv Conta é obrigatoria'),
              nrBanco: Yup.string().required('Selecione um banco'),
              nome: Yup.string().required('Nome é obrigatorio'),
              tipoConta: Yup.string().required('Selecione o tipo de conta'),
            });

        await schmeCadastro.validate(infoForm, {
          abortEarly: false,
        });

        const validation = validarCpfCnpj(infoForm.cnpjCpf);
        if (!validation) {
          refSubmit.current?.setFieldError('cnpjCpf', 'CPF/CNPJ invalido');
          throw new Error();
        }

        if (typePersonSelected === 'PF' && infoForm.cnpjCpf.length > 14) {
          refSubmit.current?.setFieldError(
            'cnpjCpf',
            'Tipo pessoa incompativel com CNPJ',
          );
          throw new Error();
        }
        if (typePersonSelected === 'PJ' && infoForm.cnpjCpf.length < 15) {
          refSubmit.current?.setFieldError(
            'cnpjCpf',
            'Tipo pessoa incompativel com CPF',
          );
          throw new Error();
        }

        const body = {
          ...infoForm,
          id: isEditting ? Number(clienteId) : null,
          idCliente: userAccount.id,
          idClienteConta: defaultAccount.contaPadrao.id,
          assinaturaFinanceira: '',
          tipoPessoa: typePersonSelected === 'PF' ? 1 : 2,
          tipoConta: Number(infoForm.tipoConta),
          nrBanco: getKey.sucesso
            ? getKey.banco.numero
            : Number(infoForm.nrBanco),
          digitoConta: getKey.sucesso
            ? getKey?.conta?.conta?.slice(-1)
            : String(infoForm.digitoConta),
          agencia: getKey.sucesso
            ? getKey.conta.agencia
            : String(infoForm.agencia),
          conta: getKey.sucesso
            ? getKey.conta.conta.slice(0, -1)
            : String(infoForm.conta),
          chavePix: getKey.sucesso ? valueKey : null,
          ativo: userActive,
          tipoChavePix: getKey.sucesso
            ? enunsChavePixFavorecido[typeKeySelected]
            : null,
        };

        try {
          const apiEndpoint = isEditting
            ? `/favorecido?idFavorecido=${Number(
                clienteId,
              )}&ativo=${userActive}`
            : '/favorecido';
          const method = isEditting ? 'put' : 'post';

          const { data } = await api_multipague_conta[method](
            apiEndpoint,
            isEditting ? null : body,
          );

          setLoading(false);
          dispatch(hideLoading(false));
          dispatch(hideLoadingButton(false));
          history.push('/Listagem/Favorecidos');

          if (data.esteira) {
            toastComponentSuccess('Favorecido encaminhado para análise');
          } else {
            toastComponentSuccess(
              !isEditting
                ? 'Favorecido salvo com sucesso'
                : 'Favorecido editado com sucesso',
            );
          }
        } catch (error) {
          setLoading(false);
          dispatch(hideLoading(false));
          dispatch(hideLoadingButton(false));

          toastComponentError(
            !isEditting
              ? error.response.data.mensagem
                ? `${
                    !!error?.response?.data?.listErrors
                      ? error?.response?.data?.listErrors[0]
                      : error?.response?.data?.mensagem
                  }`
                : 'Ocorreu um erro ao salvar o favorecido'
              : 'Ocorreu um erro ao editar o favorecido',
          );
        }
      } catch (err) {
        console.log('err:', err);
        dispatch(hideLoading(false));
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErros(err);
          refSubmit.current?.setErrors(erros);
        } else {
          if (err?.response?.data?.codigo && err?.response?.data?.mensagem) {
            toastComponentError(
              ` Codigo: ${err?.response?.data?.codigo} - ${err?.response?.data?.mensagem}`,
            );
          }
        }
      }
    },
    [
      userAccount,
      clienteId,
      typePersonSelected,
      userActive,
      isEditting,
      getKey,
    ],
  );

  const renderizaButtonGroup = useMemo(() => {
    return (
      <ContainerButtons>
        <Buttons
          variant="contained"
          title={'Voltar'}
          onClick={() => history.go(-1)}
          startIcon={
            <MdArrowBack color={useTheme().palette.background.paper} />
          }
        ></Buttons>
        <Buttons
          color="primary"
          title={'Salvar'}
          variant="contained"
          isLoading={loading}
          type="submit"
          startIcon={<MdSave color={useTheme().palette.background.paper} />}
        ></Buttons>
      </ContainerButtons>
    );
  }, [clienteId]);

  const searchKey = chavePix => {
    setIsLoading(true);
    api_multipague_transacao
      .get(`/chaves-pix/consulta-chave-pix?chave=${chavePix}`)
      .then(({ data }) => {
        setIsLoading(false);
        setGetKey(data);
      })
      .catch(error => {
        setIsLoading(false);
        setValueKey('');
        setTypeKeysSelected('');
        setGetKey(initialStateSearchKey);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(
            error?.response?.data?.mensagem
              ? error.response.data.mensagem
              : 'Não foi possível conectar ao servidor',
          );
        }
      });
  };

  const replaceKeyFormats = data => {
    return data
      ?.replace(/\./g, '')
      .replace(/-/g, '')
      ?.replace(/[.\-/()]/g, '')
      ?.replace(/ /g, '');
  };

  return (
    <Wrapper
      title={clienteId ? 'Detalhes Favorecido' : 'Cadastrar Favorecido'}
      crumb={[
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'Favorecido', link: '/Listagem/Favorecidos' },
      ]}
    >
      <Box style={{ borderRadius: '20px', boxShadow: 'none' }}>
        {!!clienteId ? (
          initialDataState.length ? (
            <Container>
              <FormContainer
                ref={refSubmit}
                onSubmit={submitInputs}
                initialData={initialDataState[0]}
              >
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <ContainerRadioGroup>
                      <RadioGroup
                        styleContainerRadio="padding: 10px 0;"
                        options={optionsTypePerson}
                        optionDefault={typePersonSelected}
                        callback={setTypePersonSelected}
                        titulo="Tipo de Pessoa"
                        name="tipoPessoa"
                      />
                    </ContainerRadioGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={2} sm={6} xs={12}>
                    {typePersonSelected === 'PF' ? (
                      <CPF id="cnpjCpf" name="cnpjCpf" disabled label="CPF" />
                    ) : (
                      <CNPJ id="cnpjCpf" name="cnpjCpf" disabled label="CNPJ" />
                    )}
                  </Grid>
                  <Grid item sm={6} md={4} xs={12}>
                    <InputText
                      id="nome"
                      disabled
                      name="nome"
                      variant="outlined"
                      label={
                        typePersonSelected === 'PF'
                          ? 'Nome Completo'
                          : 'Razão Social'
                      }
                    />
                  </Grid>

                  <Grid sm={6} md={4} item className="item-full-mobile">
                    <AutoCompleteUnform
                      options={tipoContas}
                      label="Tipo de conta"
                      id="tipoConta"
                      disabled
                      name="tipoConta"
                      defaultValue={initialDataState[0].tipoConta}
                    />
                  </Grid>

                  <Grid item sm={6} md={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={event =>
                            setUserActive(event.target.checked)
                          }
                          checked={userActive}
                          color={userActive ? 'primary' : 'secundary'}
                        />
                      }
                      label="Ativo"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
                  <Grid item sm={6} xs={12}>
                    <SelectComponent
                      itens={tipoChavePix}
                      label="Tipo chave PIX"
                      id="tipoChavePix"
                      valueDefault={tipoChavePixSelected}
                      name="tipoChavePix"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputText
                      id="chavePix"
                      name="chavePix"
                      variant="outlined"
                      label="Chave PIX"
                      disabled
                    />
                  </Grid>
                  <Grid item sm={5} xs={12} className="item-full-mobile">
                    <AutoCompleteUnform
                      options={bancos}
                      label="Banco"
                      disabled
                      id="nrBanco"
                      name="nrBanco"
                      defaultValue={initialDataState[0].nrBanco}
                    />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <InputMask
                      id="agencia"
                      name="agencia"
                      disabled
                      variant="outlined"
                      label="Agência"
                      mask="999999999"
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <InputMask
                      id="conta"
                      name="conta"
                      variant="outlined"
                      label="Conta"
                      disabled
                      mask="99999999999999999"
                    />
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <InputMask
                      id="digitoConta"
                      disabled
                      name="digitoConta"
                      variant="outlined"
                      label="DV"
                      mask="999999999"
                    />
                  </Grid>
                </Grid>
                {renderizaButtonGroup}
              </FormContainer>
            </Container>
          ) : null
        ) : (
          <Container>
            <FormContainer
              ref={refSubmit}
              onSubmit={submitInputs}
              initialData={initialDataState}
            >
              <ContainerTitle>
                <Title>Cadastrar Favorecido</Title>
              </ContainerTitle>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <RadioGroup
                    options={optionsTypePerson}
                    optionDefault={typePersonSelected}
                    callback={setTypePersonSelected}
                    titulo="Tipo de Pessoa"
                    name="tipoPessoa"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    style={{ grid: 'display', alignItems: 'baseline' }}
                    control={
                      <Switch
                        onChange={event => setUserActive(event.target.checked)}
                        checked={userActive}
                        color="primary"
                      />
                    }
                    label="Ativo"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  {typePersonSelected === 'PF' ? (
                    <CPF
                      id="cnpjCpf"
                      name="cnpjCpf"
                      isLoading={carregandoCpf.cpf}
                      label="CPF"
                      value={cpfPessoaFisica}
                      onBlur={e => setCnpjCpfConsultar({ cpf: e.target.value })}
                      onChange={event => setCpfPessoaFisica(event.target.value)}
                    />
                  ) : (
                    <CNPJ
                      id="cnpjCpf"
                      name="cnpjCpf"
                      label="CNPJ"
                      isLoading={carregandoCpf.cnpj}
                      value={cpfPessoaJuridica}
                      onBlur={e =>
                        setCnpjCpfConsultar({ cnpj: e.target.value })
                      }
                      onChange={event =>
                        setCpfPessoaJuridica(event.target.value)
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AutoCompleteUnform
                    options={tipoContas}
                    label="Tipo de conta"
                    id="tipoConta"
                    name="tipoConta"
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <InputText
                    id="nome"
                    name="nome"
                    value={
                      typePersonSelected === 'PF' ? bneficiarioNome : empresa
                    }
                    onChange={event => {
                      if (isMaster) {
                        if (typePersonSelected === 'PF') {
                          setBeneficiarioNome(event.target.value);
                        } else {
                          setEmpresa(event.target.value);
                        }
                      }
                    }}
                    variant="outlined"
                    label={
                      typePersonSelected === 'PF'
                        ? 'Nome Completo'
                        : 'Razão Social'
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={TIPO_CHAVEPIX}
                    value={typeKeySelected}
                    getOptionLabel={option => option}
                    onChange={(_, option) => {
                      setValueKey('');
                      setGetKey(initialStateSearchKey);
                      if (option) {
                        setTypeKeysSelected(option ? option : '');
                      } else {
                        setTypeKeysSelected('');
                      }
                    }}
                    style={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Selecione a Chave Pix"
                        placeholder={'Selecione a Chave Pix'}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                    InputProps={{
                      style: { borderRadius: '20px' },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {typeKeySelected !== 'E-mail' &&
                  typeKeySelected !== 'Chave Aleatória' ? (
                    <>
                      <ReactInputMask
                        mask={
                          typeKeySelected !== 'E-mail' &&
                          typeKeySelected != 'Chave Aleatória' &&
                          INPUT_CHAVEPIX_TIPO[typeKeySelected]
                        }
                        maskChar={
                          typeKeySelected !== 'E-mail' &&
                          typeKeySelected != 'Chave Aleatória'
                            ? ''
                            : null
                        }
                        value={valueKey}
                        onChange={event => {
                          setValueKey(event.target.value);
                          setGetKey('');
                        }}
                      >
                        {() => (
                          <TextField
                            id="chavePix"
                            name="chavePix"
                            size="small"
                            type={
                              INPUT_CHAVEPIX_TIPO[typeKeySelected] ===
                                'Email' && 'email'
                            }
                            label={typeKeySelected}
                            variant="outlined"
                            placeholder={'Selecione a Chave Pix'}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={
                              isLoading
                                ? {
                                    endAdornment: (
                                      <CircularProgress
                                        color="inherit"
                                        size={24}
                                        circleDisableShrink
                                      />
                                    ),
                                    style: { borderRadius: '20px' },
                                  }
                                : {
                                    endAdornment: (
                                      <MdSearch
                                        onClick={() => {
                                          if (valueKey)
                                            searchKey(
                                              replaceKeyFormats(valueKey),
                                            );
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        color="inherit"
                                        size={24}
                                      />
                                    ),
                                  }
                            }
                          />
                        )}
                      </ReactInputMask>
                    </>
                  ) : (
                    <>
                      <TextField
                        id="chavePix"
                        name="chavePix"
                        size="small"
                        type={
                          INPUT_CHAVEPIX_TIPO[typeKeySelected] === 'Email' &&
                          'email'
                        }
                        value={valueKey}
                        onChange={event => setValueKey(event.target.value)}
                        label={typeKeySelected}
                        variant="outlined"
                        required
                        placeholder={'Selecione a Chave Pix'}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={
                          isLoading
                            ? {
                                endAdornment: (
                                  <CircularProgress
                                    color="inherit"
                                    size={24}
                                    circleDisableShrink
                                  />
                                ),
                                style: { borderRadius: '20px' },
                              }
                            : {
                                endAdornment: (
                                  <MdSearch
                                    onClick={() => {
                                      if (valueKey) searchKey(valueKey);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    color="inherit"
                                    size={24}
                                  />
                                ),
                              }
                        }
                      />
                    </>
                  )}
                </Grid>
                {getKey?.sucesso ? (
                  <>
                    <Grid item sm={5} xs={12} className="item-full-mobile">
                      <TextField
                        options={bancos}
                        label="Banco"
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="nrBanco"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          getKey.banco.numero + ' - ' + getKey.banco.descricao
                        }
                        disabled
                        name="nrBanco"
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField
                        id="agencia"
                        size="small"
                        fullWidth
                        name="agencia"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={getKey.conta.agencia}
                        label="Agência"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField
                        id="conta"
                        disabled
                        fullWidth
                        name="conta"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Conta"
                        value={getKey.conta.conta}
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField
                        id="digitoConta"
                        disabled
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="digitoConta"
                        variant="outlined"
                        label="DV"
                        value={getKey.conta.conta}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={5} xs={12} className="item-full-mobile">
                      <AutoCompleteUnform
                        options={bancos}
                        label="Banco"
                        id="nrBanco"
                        name="nrBanco"
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <InputMask
                        id="agencia"
                        name="agencia"
                        variant="outlined"
                        label="Agência"
                        mask="999999999"
                      />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <InputMask
                        id="conta"
                        name="conta"
                        variant="outlined"
                        label="Conta"
                        mask="99999999999999999"
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <InputMask
                        id="digitoConta"
                        name="digitoConta"
                        variant="outlined"
                        label="DV"
                        mask="999999999"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {renderizaButtonGroup}
            </FormContainer>
          </Container>
        )}
      </Box>
    </Wrapper>
  );
};
export default NewBeneficiary;
