export const tipoContas = [
  {
    label: 'Conta Corrente',
    value: 1,
  },
  {
    label: 'Poupança',
    value: 2,
  },
  {
    label: 'Conta pagamento',
    value: 3,
  },
];

export const tipoChavePix = [
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    label: 'CNPJ',
    value: 'CNPJ',
  },
  {
    label: 'Telefone',
    value: 'TELEFONE',
  },
  {
    label: 'E-mail',
    value: 'EMAIL',
  },
  {
    label: 'Chave Aleatória',
    value: 'ALEATORIA',
  },
];

export const optionsTypeObject = [
  {
    label: 'Pessoa Física',
    value: 'PF',
    selected: true,
  },
  {
    label: 'Pessoa Jurídica',
    value: 'PJ',
    selected: false,
  },
];

export const inputChavePixTipo = {
  CPF: 'cpf_cnpj',
  CNPJ: 'cpf_cnpj',
  EMAIL: 'email',
  TELEFONE: 'telefone',
  CHAVEALEATORIA: 'CHAVEALEATORIA',
};

export const TIPO_CHAVEPIX = [
  'CNPJ',
  'CPF',
  'Telefone',
  'E-mail',
  'Chave Aleatória',
];

export const INPUT_CHAVEPIX_TIPO = {
  CPF: '999.999.999-99',
  Telefone: '(99) 99999-9999',
  CNPJ: '99.999.999/9999-99',
  'E-mail': 'E-mail',
  'Chave Aleatória': 'Chave Aleatória',
};

export const enunsChavePixFavorecido = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  Telefone: 'TELEFONE',
  'E-mail': 'EMAIL',
  'Chave Aleatória': 'ALEATORIA',
};
