import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { VscLoading } from 'react-icons/vsc';
import { AiFillEdit } from 'react-icons/ai';
import FormGroup from '@material-ui/core/FormGroup';
import { BiError } from 'react-icons/bi';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import { RiCloseCircleLine } from 'react-icons/ri';
import { AiTwotonePrinter } from 'react-icons/ai';
import { SiGooglecalendar } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { maskCpfCnpj } from '../../util/mask';
import jwt_decode from 'jwt-decode';
import {
  api_multipague_cobranca,
  api_multipague_cobranca_cnab,
  api_multipague_configuracao,
  baseUrlCobranca,
} from '../../services/api';
import formatMoney from '../../util/FormatteMoney';
import TextField from '@material-ui/core/TextField';
import DifferenceBetweenDate from '../../util/CalcularDiferencaDatas';
import Tooltip from '@material-ui/core/Tooltip';
import { FaCopy, FaQuestion, FaRegFileExcel } from 'react-icons/fa';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import { Button as ButtonMD, Typography } from '@material-ui/core';
import { ButtonTable, ContainerActionsTable, LoadingIcon } from './styles';
import {
  hideLoading,
  hideLoadingButton,
  showLoading,
  showLoadingButton,
} from '../../store/modules/loading/actions';

import { DataGridPage } from '../../components/DataGrid/DataGridPaginationServer';
import { MdPrint, MdCheckBox } from 'react-icons/md';
import { IconButton, Popover } from '@material-ui/core';
import { FaEllipsisV } from 'react-icons/fa';

import { useTheme } from '@material-ui/core/styles';
import { useAuthContext } from '../../Context/AuthContext';
import {
  toastComponentError,
  toastComponentSuccess,
  toastComponentWarn,
} from '../../components/Toast';
import formattedMoney from '../../util/FormatteMoney';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  vencidosGrid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  vencidosGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  searchGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
}));

export function newEditCnab(date, history, contaPadrao) {
  api_multipague_cobranca
    .get(`cobranca-simples/${date.id}?clienteConta=${contaPadrao.id}`)
    .then(response => {
      const formattedFileDadosBoleto = {
        dataLimiteNoFormatted: new Date(
          moment(response.data.dataLimitePagamento).format(),
        ),
        dataLimitePagamento: moment(response.data.dataLimitePagamento).format(
          'DD-MM-YYYY',
        ),
        dataVencimentoNoFormatted: new Date(
          moment(response.data.dataVencimento).format(),
        ),
        dataVencimento: moment(response.data.dataVencimento).format(
          'DD-MM-YYYY',
        ),
        numeroTitulo: response.data.numeroTitulo,
        valorTitulo: response.data.valorTitulo,
        valorAbatimento: response.data.valorAbatimento || 0,
        percAbatimento: response.data.percAbatimento || 0,
        descricaoErro: response.data.descricaoErro,
        nossoNumero: response.data.nossoNumero,
        tipoOperacao: response.data.tipoOperacao,
      };
      localStorage.setItem(
        'data_pagamento_boleto2',
        JSON.stringify(formattedFileDadosBoleto),
      );

      // ========================= DADOS PAGADOR

      const formattedFileDataPagador = {
        bairroPagador: date.bairroPagador,
        cepPagador: date.cepPagador,
        cidadePagador: date.cidadePagador,
        nomePagador: date.nomePagador,
        enderecoPagador: date.enderecoPagador,
        tipoPessoa: date.tipoPessoa,
        ufPagador: date.ufPagador,
        documentoFederal: date.documentoFederal,
      };

      localStorage.setItem(
        'data_pagador',
        JSON.stringify(formattedFileDataPagador),
      );

      // ========================= INTRUCOES

      const valJuros = response.data.instrucoes?.filter(
        j => j.tipo === 'VALOR_JUROS',
      );
      const valMulta = response.data.instrucoes?.filter(
        j => j.tipo === 'VALOR_MULTA' || j.tipo === 'ISENTO',
      );
      const valDesconto = response.data.instrucoes?.filter(
        j => j.tipo === 'VALOR_DESCONTO' || j.tipo === 'ISENTO',
      );
      const formattedFileInstrucoes = {
        dataDesconto: valDesconto.length && valDesconto[0].data,
        dataDescontoFormatted: valDesconto.length && valDesconto[0].data,
        dataJuros: valJuros.length && valJuros[0].data,
        dataJurosFormatted: valJuros.length && valJuros[0].data,
        dataMulta: valMulta.length && valMulta[0].data,
        dataMultaFormatted: valMulta.length && valMulta[0].data,
        valor_desconto: valDesconto.length ? valDesconto[0].valor : 0,
        valor_juros: valJuros.length ? valJuros[0].valor : 0,
        valor_multa: valMulta.length ? valMulta[0].valor : 0,
        id_multa: valMulta.length && valMulta[0].id,
        id_desconto: valDesconto.length && valDesconto[0].id,
        id_juros: valJuros.length && valJuros[0].id,
        mensagem1: date.mensagem1,
        mensagem2: date.mensagem2,
        observacao: date.observacao,
        tipo_calculo_juros: valJuros.length && valJuros[0].tipo,
        tipo_calculo_multa: valMulta.length ? valMulta[0].tipo : 'ISENTO',
        tipo_calculo_desconto: valDesconto.length
          ? valDesconto[0].tipo
          : 'ISENTO',
        idBoleto: date.id,
      };

      localStorage.setItem(
        'data_instrucao',
        JSON.stringify(formattedFileInstrucoes),
      );

      history.push(`/Cobranca/Simples/Editar/${date.id}`);
    })
    .catch(error => {
      console.log(error);
    });
}

const HomeCnab = () => {
  const { permissionBackEmpresa } = useAuthContext();
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const history = useHistory();
  const { userAccount } = useSelector(state => state.user);
  const { contaPadrao } = useSelector(state => state.contas);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [vencidosFilter, setVencidosFilter] = useState(false);
  const [emDiaFilter, setEmDiaFilter] = useState(false);
  const [aVencerFilter, setAVencerFilter] = useState(false);
  const [rowsTable, setRowsTable] = useState([]);
  const [rowsTableExcel, setRowsTableExcel] = useState([]);
  const accessToken = localStorage.getItem('tkn-access');
  const { realm_access, permissaoContas } = jwt_decode(accessToken);
  const [disabledActions, setDisabledActions] = useState(false);
  const [podeExcel, setPodeExcel] = useState(false);
  const [carregandoExcel, setCarregandoExcel] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [filtrouExcel, setFiltrouExcel] = useState(false);
  const [idErrors, setIdErrors] = React.useState([]);
  const [rowCount, setRowCount] = useState();
  const [totalizando, setTotalizando] = useState(0);
  const [gridSelection, setGridSelection] = useState([]);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [filtrouPagina, setFiltrouPagina] = useState(0);
  const [infoCnab, setInfoCnab] = useState();
  const [situacao, setSituacao] = useState(null);
  const [situacaoPadrao, setSituacaoPadrao] = useState('Selecione a situação');
  const [regraSituacao, setRegraSituacao] = useState('');
  const [dataSolicitacaoDe, setDataSolicitacaoDe] = useState('');
  const [dataSolicitacaoAte, setDataSolicitacaoAte] = useState('');
  const [dataPagamentoDe, setDataPagamentoDe] = useState('');
  const [dataPagamentoAte, setDataPagamentoAte] = useState('');
  const [textNoRows, setTextNoRows] = useState('');
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [stateInput, setStateInput] = useState(null);
  const [tipoOperacao, setTipoOperacao] = useState();
  const [inputValueTipoOperacao, setInputValueTipoOperacao] = useState('');
  const [selectionModel, setSelectionModel] = React.useState([]);

  const selecionarSituacao = [
    {
      id: 'PENDENTE_REGISTRO',
      descricao: 'Aguardando Registro',
    },
    {
      id: 'REGISTRADO',
      descricao: 'Registrado',
    },
    {
      id: 'ERRO_REGISTRO',
      descricao: 'Erro Registro',
    },
    {
      id: 'PAGO',
      descricao: 'Pago',
    },
    {
      id: 'BAIXADO_DATA_LIMITE_PAGTO',
      descricao: 'Baixado Data Limite Pagamento',
    },
    {
      id: 'PENDENTE_BAIXA_CEDENTE',
      descricao: 'Aguardando Cancelamento',
    },
    {
      id: 'BAIXADO_CEDENTE',
      descricao: 'Cancelado',
    },
    {
      id: 'ERRO_BAIXA_CEDENTE',
      descricao: 'Erro Baixa Cedente',
    },
    {
      id: 'ALTERACAO_PENDENTE',
      descricao: 'Aguardando Alteração',
    },
    {
      id: 'ERRO_ALTERACAO',
      descricao: 'Erro Alteração',
    },
  ].sort((a, b) =>
    a.descricao.toLowerCase() > b.descricao.toLowerCase()
      ? 1
      : b.descricao.toLowerCase() > a.descricao.toLowerCase()
      ? -1
      : 0,
  );

  const tipoOperacaoParam = !!tipoOperacao
    ? `&tipoOperacao=${tipoOperacao}`
    : '';

  const handleSolicitacaoDe = event => {
    setDataSolicitacaoDe(event.target.value);
  };
  const handleSolicitacaoAte = event => {
    setDataSolicitacaoAte(event.target.value);
  };
  const handlePagamentoDe = event => {
    setDataPagamentoDe(event.target.value);
  };
  const handlePagamentoAte = event => {
    setDataPagamentoAte(event.target.value);
  };

  const [nossoNumero, setNossoNumero] = useState();
  const [numeroDocumento, setNumeroDocumento] = useState();
  const [nomeCNAB, setNomeCNAB] = useState('');
  const [selectInstrucao, setSelectInstrucao] = useState();
  const [selectInstrucaoValue, setSelectInstrucaoValue] = useState();
  const [selectMotivo, setSelectMotivo] = useState();
  const [selectMotivoValue, setSelectMotivoValue] = useState();
  const [observacao, setObservacao] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [carregandoDadosExcel, setCarregandoDadosExcel] = useState(false);
  const [copied, setCopied] = useState({});

  const handleCopy = event => {
    navigator.clipboard.writeText(event.row.nomeArquivoBancario);
    setCopied(prevState => ({
      ...prevState,
      [event.row.id]: true,
    }));
  };

  const columnsTable = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return carregandoDadosExcel ? (
          <CircularProgress size={24} />
        ) : (
          <Checkbox
            checked={checkAll}
            onChange={(_e, value) => {
              changeCheckboxAll(value);
            }}
            color="primary"
          />
        );
      },
      renderCell: event => {
        return (
          <Checkbox
            checked={event.row.registrado && selectionModel.includes(event.id)}
            onChange={(_e, checked) => {
              if (!checked && event.row.registrado) {
                setCheckAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              }
            }}
            disabled={!event.row.registrado}
            color="primary"
          />
        );
      },
    },
    {
      field: 'nomeRazaoPagador',
      headerName: 'Sacado',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'documentoFederal',
      headerName: 'CPF/CNPJ',
      type: 'string',
      width: 150,
    },
    {
      field: 'numeroTitulo',
      headerName: 'Nº Boleto',
      type: 'string',
      width: 150,
    },
    {
      field: 'valor',
      headerName: 'Valor',
      type: 'string',
      width: 150,
    },
    {
      field: 'dataVencimentoFormatted',
      headerName: 'Vencimento',
      type: 'string',
      width: 150,
    },
    {
      field: 'dataPagamentoFormatted',
      headerName: 'Pagamento',
      type: 'string',
      width: 150,
    },
    {
      field: 'diferencaDiasFormatted',
      headerName: 'Dif Dias',
      width: 110,
      align: 'center',
    },
    {
      field: 'tipoOperacao',
      headerName: 'Tipo Operação',
      width: 110,
      align: 'center',
      renderCell: event => (
        <>
          {event.row.tipoOperacao === 'COBRANCA_SIMPLES'
            ? 'Simples'
            : event.row.tipoOperacao === 'OPERACAO_GARANTIA'
            ? 'Garantia'
            : null}
        </>
      ),
    },
    {
      field: 'statusNormal',
      headerName: 'Status',
      width: 140,
    },
    {
      field: 'error',
      headerName: 'Erro',
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <ButtonTable
            hasError={true}
            onClick={() => editarArquivoCnab(event.row)}
          >
            <BiError
              size={24}
              color={
                !!event.row.codigoErro ? '#dc3545' : theme.palette.text.disabled
              }
            />
          </ButtonTable>
        );
      },
    },
    {
      field: 'cnab',
      headerName: 'CNAB',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: event => {
        return event.row.nomeArquivoBancario ? (
          <ContainerActionsTable>
            <Tooltip
              aria-label="cnab"
              placement="top"
              arrow
              title={
                <React.Fragment>
                  <Typography variant="caption">Copiar: </Typography>
                  <br />
                  <Typography variant="body2">
                    {event.row.nomeArquivoBancario}
                  </Typography>
                </React.Fragment>
              }
            >
              <ButtonTable
                color={'primary'}
                onClick={() => handleCopy(event)}
                onMouseLeave={() => setCopied({})}
              >
                {copied[event.row.id] ? (
                  <MdCheckBox
                    size={23}
                    style={{ marginBottom: '2px' }}
                    color={theme.palette.primary.main}
                  />
                ) : (
                  <FaCopy
                    style={{ marginBottom: '5px' }}
                    size={20}
                    color={theme.palette.primary.main}
                  />
                )}
              </ButtonTable>
            </Tooltip>
          </ContainerActionsTable>
        ) : (
          <ContainerActionsTable>
            <Tooltip
              aria-label="cnab"
              placement="top"
              arrow
              title={
                <React.Fragment>
                  <Typography variant="caption">Sem CNAB</Typography>
                </React.Fragment>
              }
            >
              <ButtonTable color={'primary'}>
                <FaCopy
                  style={{ marginBottom: '5px' }}
                  size={20}
                  color={theme.palette.text.disabled}
                />
              </ButtonTable>
            </Tooltip>
          </ContainerActionsTable>
        );
      },
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: event => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          <>
            <IconButton onClick={handleClick} sx={{ mt: 2 }}>
              <FaEllipsisV aria-describedby={id} variant="contained" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <>
                <ContainerActionsTable>
                  {!disabledActions ? (
                    <Tooltip title="Cancelar" aria-label="cancelar">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        onClick={
                          habilitarAcoesTable(
                            'c',
                            event.row.status.valor,
                            event.row,
                          ) &&
                          (() =>
                            history.push(
                              `/Cancelar/Boleto/true/${event.row.id}`,
                            ))
                        }
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <RiCloseCircleLine
                          size={24}
                          color={theme.palette.primary.main}
                        />
                      </ButtonTable>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Cancelar" aria-label="cancelar">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        style={{
                          cursor: 'no-drop',
                        }}
                      >
                        <RiCloseCircleLine
                          size={24}
                          color={theme.palette.text.disabled}
                        />
                      </ButtonTable>
                    </Tooltip>
                  )}
                </ContainerActionsTable>
                <ContainerActionsTable>
                  <Tooltip title="Editar" aria-label="editar">
                    <ButtonTable
                      hasError={!!event.row.codigoErro}
                      onClick={() =>
                        !disabledActions &&
                        habilitarAcoesTable(
                          'e',
                          event.row.status.valor,
                          event.row,
                        ) &&
                        newEditCnab(event.row, history, contaPadrao)
                      }
                      style={{
                        cursor: disabledActions
                          ? 'no-drop'
                          : habilitarAcoesTable(
                              'e',
                              event.row.status.valor,
                              event.row,
                            )
                          ? 'pointer'
                          : 'no-drop',
                      }}
                    >
                      <AiFillEdit
                        size={24}
                        color={
                          disabledActions
                            ? theme.palette.text.disabled
                            : habilitarAcoesTable(
                                'e',
                                event.row.status.valor,
                                event.row,
                              )
                            ? theme.palette.primary.main
                            : theme.palette.text.disabled
                        }
                      />
                    </ButtonTable>
                  </Tooltip>
                </ContainerActionsTable>
                <ContainerActionsTable>
                  {event.row.loading ? (
                    <LoadingIcon>
                      <VscLoading
                        size={24}
                        color={theme.palette.primary.main}
                      />
                    </LoadingIcon>
                  ) : (
                    <Tooltip title="Imprimir" aria-label="imprimir">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        onClick={
                          habilitarAcoesTable(
                            'i',
                            event.row.status.valor,
                            event.row,
                          ) &&
                          (() =>
                            imprimirBoleto(
                              event.row.id,
                              event.row.status.valor == 'BAIXADO_CEDENTE'
                                ? true
                                : false,
                            ))
                        }
                        style={{
                          cursor: habilitarAcoesTable(
                            'i',
                            event.row.status.valor,
                            event.row,
                          )
                            ? 'pointer'
                            : 'no-drop',
                        }}
                      >
                        <AiTwotonePrinter
                          size={24}
                          color={
                            habilitarAcoesTable(
                              'i',
                              event.row.status.valor,
                              event.row,
                            )
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled
                          }
                        />
                      </ButtonTable>
                    </Tooltip>
                  )}
                </ContainerActionsTable>
              </>
            </Popover>
          </>
        );
      },
    },
  ];

  const columnsTableExcel = [
    {
      field: '__check__',
      type: 'checkboxSelection',
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      renderHeader: () => {
        return carregandoDadosExcel ? (
          <CircularProgress size={24} />
        ) : (
          <Checkbox
            checked={checkAll}
            onChange={(_e, value) => {
              changeCheckboxAll(value);
            }}
            color="primary"
          />
        );
      },
      renderCell: event => {
        return (
          <Checkbox
            checked={event.row.registrado && selectionModel.includes(event.id)}
            onChange={(_e, checked) => {
              if (!checked && event.row.registrado) {
                setCheckAll(false);
                setSelectionModel(old => old.filter(id => id !== event.id));
              }
            }}
            disabled={!event.row.registrado}
            color="primary"
          />
        );
      },
    },
    {
      field: 'nomeRazaoPagadorExcel',
      headerName: 'Sacado',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'documentoFederalExcel',
      headerName: 'CPF/CNPJ',
      type: 'string',
      width: 150,
    },
    {
      field: 'numeroTituloExcel',
      headerName: 'Nº Boleto',
      type: 'string',
      width: 150,
    },
    {
      field: 'valorExcel',
      headerName: 'Valor',
      type: 'string',
      width: 150,
    },
    {
      field: 'dataVencimentoFormattedExcel',
      headerName: 'Vencimento',
      type: 'string',
      width: 150,
    },
    {
      field: 'dataPagamentoFormattedExcel',
      headerName: 'Pagamento',
      type: 'string',
      width: 150,
    },
    {
      field: 'diferencaDiasFormattedExcel',
      headerName: 'Dif Dias',
      width: 110,
      align: 'center',
    },
    {
      field: 'tipoOperacao',
      headerName: 'Tipo Operação',
      width: 110,
      align: 'center',
      renderCell: event => (
        <>
          {event.row.tipoOperacao === 'COBRANCA_SIMPLES'
            ? 'Simples'
            : event.row.tipoOperacao === 'OPERACAO_GARANTIA'
            ? 'Garantia'
            : null}
        </>
      ),
    },
    {
      field: 'statusExcel',
      headerName: 'Status',
      sortable: false,
      width: 140,
      disableClickEventBubbling: true,
      renderCell: event => <>{event.row.status?.descricao}</>,
    },
    {
      field: 'error',
      headerName: 'Erro',
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: event => {
        return (
          <ButtonTable
            hasError={true}
            onClick={() => editarArquivoCnab(event.row)}
          >
            <BiError
              size={24}
              color={
                !!event.row.codigoErro ? '#dc3545' : theme.palette.text.disabled
              }
            />
          </ButtonTable>
        );
      },
    },
    {
      field: 'cnab',
      headerName: 'CNAB',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: event => {
        return event.row.nomeArquivoBancario ? (
          <ContainerActionsTable>
            <Tooltip
              aria-label="cnab"
              placement="top"
              arrow
              title={
                <React.Fragment>
                  <Typography variant="caption">Copiar: </Typography>
                  <br />
                  <Typography variant="body2">
                    {event.row.nomeArquivoBancario}
                  </Typography>
                </React.Fragment>
              }
            >
              <ButtonTable
                color={'primary'}
                onClick={() => handleCopy(event)}
                onMouseLeave={() => setCopied({})}
              >
                {copied[event.row.id] ? (
                  <MdCheckBox
                    size={23}
                    style={{ marginBottom: '2px' }}
                    color={theme.palette.primary.main}
                  />
                ) : (
                  <FaCopy
                    style={{ marginBottom: '5px' }}
                    size={20}
                    color={theme.palette.primary.main}
                  />
                )}
              </ButtonTable>
            </Tooltip>
          </ContainerActionsTable>
        ) : (
          <ContainerActionsTable>
            <Tooltip
              aria-label="cnab"
              placement="top"
              arrow
              title={
                <React.Fragment>
                  <Typography variant="caption">Sem CNAB</Typography>
                </React.Fragment>
              }
            >
              <ButtonTable color={'primary'}>
                <FaCopy
                  style={{ marginBottom: '5px' }}
                  size={20}
                  color={theme.palette.text.disabled}
                />
              </ButtonTable>
            </Tooltip>
          </ContainerActionsTable>
        );
      },
    },
    {
      field: 'acoes',
      align: 'center',
      headerName: 'Ações',
      headerAlign: 'center',
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: event => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = event => {
          setAnchorEl(event.currentTarget);
          setOpenMenuActions(true);
        };

        const handleClose = () => {
          setAnchorEl(null);
          setOpenMenuActions(false);
        };

        const open =
          Boolean(anchorEl) && openMenuActions && event.row.id === rowId;
        const id = open ? 'simple-popover' : undefined;
        return (
          <>
            <IconButton onClick={handleClick} sx={{ mt: 2 }}>
              <FaEllipsisV aria-describedby={id} variant="contained" />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '10px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <>
                <ContainerActionsTable>
                  {!disabledActions ? (
                    <Tooltip title="Cancelar" aria-label="cancelar">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        onClick={
                          habilitarAcoesTable(
                            'c',
                            event.row.status.valor,
                            event.row,
                          ) &&
                          (() =>
                            history.push(
                              `/Cancelar/Boleto/true/${event.row.id}`,
                            ))
                        }
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <RiCloseCircleLine
                          size={24}
                          color={theme.palette.primary.main}
                        />
                      </ButtonTable>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Cancelar" aria-label="cancelar">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        style={{
                          cursor: 'no-drop',
                        }}
                      >
                        <RiCloseCircleLine
                          size={24}
                          color={theme.palette.text.disabled}
                        />
                      </ButtonTable>
                    </Tooltip>
                  )}
                </ContainerActionsTable>
                <ContainerActionsTable>
                  <Tooltip title="Editar" aria-label="editar">
                    <ButtonTable
                      hasError={!!event.row.codigoErro}
                      onClick={() =>
                        !disabledActions &&
                        habilitarAcoesTable(
                          'e',
                          event.row.status.valor,
                          event.row,
                        ) &&
                        newEditCnab(event.row, history, contaPadrao)
                      }
                      style={{
                        cursor: disabledActions
                          ? 'no-drop'
                          : habilitarAcoesTable(
                              'e',
                              event.row.status.valor,
                              event.row,
                            )
                          ? 'pointer'
                          : 'no-drop',
                      }}
                    >
                      <AiFillEdit
                        size={24}
                        color={
                          disabledActions
                            ? theme.palette.text.disabled
                            : habilitarAcoesTable(
                                'e',
                                event.row.status.valor,
                                event.row,
                              )
                            ? theme.palette.primary.main
                            : theme.palette.text.disabled
                        }
                      />
                    </ButtonTable>
                  </Tooltip>
                </ContainerActionsTable>
                <ContainerActionsTable>
                  {event.row.loading ? (
                    <LoadingIcon>
                      <VscLoading
                        size={24}
                        color={theme.palette.primary.main}
                      />
                    </LoadingIcon>
                  ) : (
                    <Tooltip title="Imprimir" aria-label="imprimir">
                      <ButtonTable
                        hasError={!!event.row.codigoErro}
                        onClick={
                          habilitarAcoesTable(
                            'i',
                            event.row.status.valor,
                            event.row,
                          ) &&
                          (() =>
                            imprimirBoleto(
                              event.row.id,
                              event.row.status.valor == 'BAIXADO_CEDENTE'
                                ? true
                                : false,
                            ))
                        }
                        style={{
                          cursor: habilitarAcoesTable(
                            'i',
                            event.row.status.valor,
                            event.row,
                          )
                            ? 'pointer'
                            : 'no-drop',
                        }}
                      >
                        <AiTwotonePrinter
                          size={24}
                          color={
                            habilitarAcoesTable(
                              'i',
                              event.row.status.valor,
                              event.row,
                            )
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled
                          }
                        />
                      </ButtonTable>
                    </Tooltip>
                  )}
                </ContainerActionsTable>
              </>
            </Popover>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (permissionBackEmpresa) {
      setDisabledActions(true);
    } else {
      setDisabledActions(false);
    }
  }, [realm_access, permissaoContas]);

  useEffect(() => {
    if (!!userAccount?.id && !!contaPadrao?.id) {
      loadDefaultValueCarteira();
    }
  }, [userAccount?.id, contaPadrao?.id]);

  async function loadDefaultValueCarteira() {
    try {
      dispatch(showLoading(true));
      const { data } = await api_multipague_configuracao.get(
        `/carteira?cliente=${userAccount.id}&clienteConta=${contaPadrao.id}`,
      );
      if (data.length) {
        localStorage.setItem('carteira_cliente', JSON.stringify(data[0]));
        const tipoJuros =
          (data[0].tipoJuros === 'VALOR' && 'VALOR_JUROS') ||
          (data[0].tipoJuros === 'PERCENTUAL_MES' && 'PERCENTUAL_JUROS_MES') ||
          (data[0].tipoJuros === 'PERCENTUAL_DIA' && 'PERCENTUAL_JUROS_DIA') ||
          (data[0].tipoJuros === 'ISENTO' && 'ISENTO');
        const tipoMulta =
          (data[0].tipoMulta === 'VALOR_FIXO' && 'VALOR_MULTA') ||
          (data[0].tipoMulta === 'PERCENTUAL' && 'PERCENTUAL_MULTA') ||
          (data[0].tipoMulta === 'ISENTO' && 'ISENTO');

        const localStorageData = {
          valor_juros: data[0].taxaJuros,
          valor_multa: data[0].taxaMulta,
          tipo_calculo_juros: tipoJuros,
          tipo_calculo_multa: tipoMulta,
        };
        localStorage.setItem(
          'data_instrucao',
          JSON.stringify(localStorageData),
        );
      }
      dispatch(hideLoading(false));
    } catch (err) {
      dispatch(hideLoading(false));
      if (err.response.status !== 404) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
      }
    }
  }

  function habilitarAcoesTable(type, status, row) {
    const status_disabledAll = [
      'PENDENTE_REGISTRO',
      'BAIXADO_DATA_LIMITE_PAGTO',
      'PENDENTE_BAIXA_CEDENTE',
      'BAIXADO_CEDENTE',
      'ALTERACAO_PENDENTE',
      'PAGO',
    ];

    const status_ableAll = ['REGISTRADO', 'ERRO_BAIXA_CEDENTE'];

    const status_changeError = ['ERRO_ALTERACAO'];

    const status_registerError = ['ERRO_REGISTRO'];

    const isDisabledAll =
      status !== 'BAIXADO_CEDENTE'
        ? status_disabledAll.filter(disabled => disabled === status)
        : 0;
    const isabledAll = status_ableAll.filter(able => able === status);
    const isChangeError = status_changeError.filter(
      cError => cError === status,
    );
    const isRegisterError = status_registerError.filter(
      rError => rError === status,
    );

    switch (type) {
      case 'c':
        if (isDisabledAll.length) {
          return false;
        }
        if (isabledAll.length) {
          return true;
        }
        if (isChangeError.length && !!row.registrado) {
          return true;
        } else if (isChangeError.length) {
          return false;
        }
        if (isRegisterError.length) {
          return false;
        }
      case 'p':
        if (isDisabledAll.length) {
          return false;
        }
        if (isabledAll.length) {
          return true;
        }
        if (isChangeError.length && !!row.registrado) {
          return true;
        } else if (isChangeError.length) {
          return false;
        }
        if (isRegisterError.length) {
          return false;
        }

      case 'e':
        if (isDisabledAll.length) {
          return false;
        }
        if (isabledAll.length) {
          return true;
        }
        if (isChangeError.length && !!row.registrado) {
          return true;
        } else if (isChangeError.length) {
          return true;
        }
        if (isRegisterError.length) {
          return true;
        }
      case 'i':
        if (isDisabledAll.length) {
          return false;
        }
        if (isabledAll.length) {
          return true;
        }
        if (isChangeError.length && !!row.registrado) {
          return true;
        } else if (isChangeError.length) {
          return false;
        }
        if (isRegisterError.length) {
          return false;
        }
      default:
        return true;
    }
  }

  useEffect(() => {
    if (contaPadrao?.id) {
      submitForm({}, 0, rowsState.pageSize);
    }
  }, [contaPadrao]);

  const handlePageSize = pageSize => {
    setRowsState(prev => ({ ...prev, pageSize: pageSize }));
    submitForm(infoCnab, 0, pageSize);
  };
  const handleSearch = () => {
    setSelectionModel([]);
    submitForm(infoCnab, 0, rowsState.pageSize);
    setCheckAll(false);
  };

  const submitForm = useCallback(
    async (infos, page = 0, pageSize = 10, cleanFilters = false) => {
      try {
        setLoadingTable(true);
        setRowsState(prev => ({ ...prev, page: page, pageSize: pageSize }));
        dispatch(showLoadingButton(true));
        const config = {
          headers: {
            ContentType: 'application/paged+json',
          },
        };
        const situationArray = [];

        if (vencidosFilter) situationArray.push('VENCIDO');
        if (emDiaFilter) situationArray.push('VENCENDO');
        if (aVencerFilter) situationArray.push('A_VENCER');

        const { data } = await api_multipague_cobranca_cnab.get(
          cleanFilters
            ? `cobranca-simples?clienteConta=${contaPadrao.id}&page=0&size=${pageSize}`
            : `cobranca-simples?size=${pageSize}&clienteConta=${
                contaPadrao.id
              }&page=${page}&situacao=${regraSituacao}&nomePagador=${descricao}&numeroTitulo=${
                numeroDocumento ? numeroDocumento : ''
              }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
                dataPagamentoDe !== '' && dataPagamentoAte !== ''
                  ? 'PAGO'
                  : situacao
                  ? situacao
                  : ''
              }&dataInicial=${
                dataSolicitacaoDe !== ''
                  ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
                  : ''
              }&dataFinal=${
                dataSolicitacaoAte !== ''
                  ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
                  : ''
              }&dataPagamentoInicial=${
                dataPagamentoDe !== ''
                  ? moment(dataPagamentoDe).format('YYYY-MM-DD').trim()
                  : ''
              }&dataPagamentoFinal=${
                dataPagamentoAte !== ''
                  ? moment(dataPagamentoAte).format('YYYY-MM-DD').trim()
                  : ''
              }&cpfCnpj=${codigo.replace(
                /[^\d]/g,
                '',
              )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
          config,
        );

        const formatted = data.content.map(info => {
          if (info.registrado !== true) {
            setIdErrors(_old => [..._old, info.id]);
          }
          return {
            ...info,
            nomeRazaoPagadorExcel: info.nomePagador,
            numeroTituloExcel: info.numeroTitulo,
            documentoFederalExcel: maskCpfCnpj(info.documentoFederal),
            nomeArquivoBancario: info.nomeArquivoBancario,
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            dataVencimentoFormattedExcel:
              info.dataVencimento &&
              moment(info.dataVencimento).format('DD/MM/YYYY'),
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            valorPagoExcel: info.valorPago && formatMoney(info.valorPago),
            valorExcel: info.valorTitulo && formatMoney(info.valorTitulo),
            codigoBarrasExcel: info.codigoBarras,
            nossoNumeroExcel: info.nossoNumero,
            dataRegistroFormattedExcel:
              info.dataRegistro &&
              moment(info.dataRegistro).format('DD/MM/YYYY'),
            diferencaDiasFormattedExcel:
              info.dataVencimento &&
              DifferenceBetweenDate(
                moment(info.dataVencimento).format('DD/MM/YYYY'),
                moment(Date.now()).format('DD/MM/YYYY'),
              ),
            id: info.id,
            situacaoExcel: info.registrado,
            situacaoTableExcel: info.status.descricao,
            tipoOperacao: info.tipoOperacao,
            loading: false,
          };
        });

        const qtdItems = data.totalElements;
        setRowCount(qtdItems);
        setPodeExcel(false);
        setLoadingTable(false);
        setFiltrouExcel(true);
        setRowsTable(formatted);
        setRowsTableExcel(formatted);
        dispatch(hideLoadingButton(false));
        setInfoCnab(infos);
      } catch (err) {
        dispatch(hideLoadingButton(false));
        setRowCount(0);
        setRowsTable([]);
        setPodeExcel(false);
        setLoadingTable(false);
        setRowsTableExcel([]);
        setSelectionModel([]);
        setTextNoRows(err.response.data.mensagem);
        if (err.response.status !== 404) {
          toastComponentError(
            ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          );
        }
      }
    },
    [
      dataSolicitacaoDe,
      dataSolicitacaoAte,
      dataPagamentoDe,
      dataPagamentoAte,
      codigo,
      nossoNumero,
      nomeCNAB,
      numeroDocumento,
      contaPadrao,
      vencidosFilter,
      emDiaFilter,
      aVencerFilter,
      filtrouPagina,
      codigo,
      descricao,
      situacao,
      tipoOperacao,
      regraSituacao,
      rowsState,
    ],
  );

  const editarArquivoCnab = useCallback(date => {
    const formattedFileDadosBoleto = {
      dataLimiteNoFormatted: new Date(
        moment(date.dataLimitePagamento).format(),
      ),
      dataLimitePagamento: moment(date.dataLimitePagamento).format(
        'DD-MM-YYYY',
      ),
      dataVencimentoNoFormatted: new Date(moment(date.dataVencimento).format()),
      dataVencimento: moment(date.dataVencimento).format('DD-MM-YYYY'),
      numeroTitulo: date.numeroTitulo,
      valorTitulo: date.valorTitulo,
      valorAbatimento: date.valorAbatimento || 0,
      percAbatimento: date.percAbatimento || 0,
      descricaoErro: date.descricaoErro,
      nossoNumero: date.nossoNumero,
      tipoOperacao: date.tipoOperacao,
    };
    localStorage.setItem(
      'data_pagamento_boleto',
      JSON.stringify(formattedFileDadosBoleto),
    );

    // ========================= DADOS PAGADOR

    const formattedFileDataPagador = {
      bairroPagador: date.bairroPagador,
      cepPagador: date.cepPagador,
      cidadePagador: date.cidadePagador,
      nomePagador: date.nomePagador,
      enderecoPagador: date.enderecoPagador,
      tipoPessoa: date.tipoPessoa,
      ufPagador: date.ufPagador,
      documentoFederal: date.documentoFederal,
    };

    localStorage.setItem(
      'data_pagador',
      JSON.stringify(formattedFileDataPagador),
    );

    // ========================= INTRUCOES

    const valJuros = date.instrucoes?.filter(j => j.tipo === 'VALOR_JUROS');
    const valMulta = date.instrucoes?.filter(
      j => j.tipo === 'VALOR_MULTA' || j.tipo === 'ISENTO',
    );
    const valDesconto = date.instrucoes?.filter(
      j => j.tipo === 'VALOR_DESCONTO' || j.tipo === 'ISENTO',
    );
    const formattedFileInstrucoes = {
      dataDesconto: valDesconto.length && valDesconto[0].data,
      dataDescontoFormatted: valDesconto.length && valDesconto[0].data,
      dataJuros: valJuros.length && valJuros[0].data,
      dataJurosFormatted: valJuros.length && valJuros[0].data,
      dataMulta: valMulta.length && valMulta[0].data,
      dataMultaFormatted: valMulta.length && valMulta[0].data,
      valor_desconto: valDesconto.length ? valDesconto[0].valor : 0,
      valor_juros: valJuros.length ? valJuros[0].valor : 0,
      valor_multa: valMulta.length ? valMulta[0].valor : 0,
      id_multa: valMulta.length && valMulta[0].id,
      id_desconto: valDesconto.length && valDesconto[0].id,
      id_juros: valJuros.length && valJuros[0].id,
      mensagem1: date.mensagem1,
      mensagem2: date.mensagem2,
      observacao: date.observacao,
      tipo_calculo_juros: valJuros.length && valJuros[0].tipo,
      tipo_calculo_multa: valMulta.length ? valMulta[0].tipo : 'ISENTO',
      tipo_calculo_desconto: valDesconto.length
        ? valDesconto[0].tipo
        : 'ISENTO',
      idBoleto: date.id,
    };

    localStorage.setItem(
      'data_instrucao',
      JSON.stringify(formattedFileInstrucoes),
    );

    history.push(`/Cobranca/Simples/Editar/${date.id}`);
  }, []);

  const imprimirBoleto = useCallback(
    async (id, cancelamento) => {
      if (cancelamento) {
        imprimirBoletoCancelamento(id);
      } else {
        const fomatted = rowsTable.map(row => ({
          ...row,
          loading: row.id === id ? true : row.loading,
        }));
        setRowsTable(fomatted);

        try {
          const { data } = await api_multipague_cobranca.get(
            `/cobranca-simples/${id}/imprimir?clienteConta=${contaPadrao.id}`,
          );

          if (data) {
            const anchor = document.createElement('a');
            document.body.appendChild(anchor);
            const file = `${baseUrlCobranca}/cobranca-simples/${id}/imprimir?clienteConta=${contaPadrao.id}`;
            const token = localStorage.getItem('tkn-access');
            const headers = new Headers();
            headers.append('Authorization', `Bearer ${token}`);
            headers.append('ContentType', 'application/json');

            fetch(file, { headers })
              .then(response => response.blob())
              .then(blobby => {
                const objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = 'multipague.pdf';
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
              });
          }

          const fomatted2 = rowsTable.map(row => ({
            ...row,
            loading: row.id === id ? false : row.loading,
          }));

          setRowsTable(fomatted2);
        } catch (err) {
          const fomatted3 = rowsTable.map(row => ({
            ...row,
            loading: row.id === id && false,
          }));

          setRowsTable(fomatted3);
          dispatch(hideLoading(false));
          toastComponentError(
            `Codigo: ${err?.response.data.codigo} - ${err?.response.data.mensagem}`,
          );
        }
      }
    },
    [contaPadrao, rowsTable],
  );

  const imprimirBoletoCancelamento = id => {
    try {
      const infoBoletosCancelados =
        rowsTable.length > 0
          ? rowsTable.filter(item => item.id == id)
          : rowsTableExcel.filter(item => item.id == id);
      localStorage.setItem(
        'boleto-cancelado',
        JSON.stringify(infoBoletosCancelados),
      );
      window.open('/imprimir-boleto-cancelamento');
    } catch (err) {}
  };

  useEffect(() => {
    const selectedRowsFilter = gridSelection.filter(row =>
      selectionModel.includes(row.id),
    );

    const removeDuplicates = array => {
      return array.filter((item, index, self) => {
        return index === self.findIndex(t => t.id === item.id);
      });
    };

    const str = removeDuplicates(selectedRowsFilter).map(
      totalizador => totalizador.valorTitulo,
    );
    function getSum(total, num) {
      return total + num;
    }
    setTotalizando(str.reduce(getSum, 0));
  }, [selectionModel]);

  const [state, setState] = React.useState({
    checkedVencidos: false,
    checkedNodia: false,
    checkedAvencer: false,
  });
  const handleChangeNodia = event => {
    setState({ checkedNodia: !state.checkedNodia });
    setRegraSituacao(event.target.checked ? 'VENCENDO' : '');
  };
  const handleChangeVencidos = event => {
    setState({ checkedVencidos: !state.checkedVencidos });
    setRegraSituacao(event.target.checked ? 'VENCIDO' : '');
  };
  const handleChangeAvencer = event => {
    setState({ checkedAvencer: !state.checkedAvencer });
    setRegraSituacao(event.target.checked ? 'A_VENCER' : '');
  };

  const solicitarExcel = useCallback(async () => {
    setCheckAll(false);
    setCarregandoExcel(true);
    const config = {
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
      },
    };
    const situationArray = [];

    if (vencidosFilter) situationArray.push('VENCIDO');
    if (emDiaFilter) situationArray.push('VENCENDO');
    if (aVencerFilter) situationArray.push('A_VENCER');
    try {
      if (selectionModel.length > 0) {
        const { data } = await api_multipague_cobranca_cnab
          .get(
            `cobranca-simples?clienteConta=${
              contaPadrao.id
            }&situacao=${regraSituacao}&nomePagador=${descricao}&numeroTitulo=${
              numeroDocumento ? numeroDocumento : ''
            }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
              dataPagamentoDe !== '' && dataPagamentoAte !== ''
                ? 'PAGO'
                : situacao
                ? situacao
                : ''
            }&dataInicial=${
              dataSolicitacaoDe !== ''
                ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataFinal=${
              dataSolicitacaoAte !== ''
                ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoInicial=${
              dataPagamentoDe !== ''
                ? moment(dataPagamentoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoFinal=${
              dataPagamentoAte !== ''
                ? moment(dataPagamentoAte).format('YYYY-MM-DD').trim()
                : ''
            }&cpfCnpj=${codigo.replace(
              /[^\d]/g,
              '',
            )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
            config,
          )
          .catch(error => {
            toastComponentError(error.response.data.mensagem);
          });
        const formatted = data
          .filter(item =>
            checkAll ? item === item : selectionModel.includes(item.id),
          )
          .map(info => {
            return {
              ...info,
              nomeRazaoPagador: info.nomePagador,
              documentoFederal: maskCpfCnpj(info.documentoFederal),
              numeroTitulo: info.numeroTitulo,
              nomeArquivoBancario: info.nomeArquivoBancario,
              dataPagamentoFormatted:
                info.dataPagamento &&
                moment(info.dataPagamento).format('DD/MM/YYYY'),
              dataVencimentoFormatted:
                info.dataVencimento &&
                moment(info.dataVencimento).format('DD/MM/YYYY'),
              dataPagamentoFormatted:
                info.dataPagamento &&
                moment(info.dataPagamento).format('DD/MM/YYYY'),
              valorPago: info.valorPago && formatMoney(info.valorPago),
              valor: info.valorTitulo && formatMoney(info.valorTitulo),
              codigoBarras: info.codigoBarras,
              nossoNumero: info.nossoNumero,
              numeroTitulo: info.numeroTitulo,
              dataRegistroFormatted:
                info.dataRegistro &&
                moment(info.dataRegistro).format('DD/MM/YYYY'),
              diferencaDiasFormatted:
                info.dataVencimento &&
                DifferenceBetweenDate(
                  moment(info.dataVencimento).format('DD/MM/YYYY'),
                  moment(Date.now()).format('DD/MM/YYYY'),
                ),
              id: info.id,
              situacao: info.registrado,
              statusNormal: info.status.descricao,
              loading: false,
              tipoOperacao: info.tipoOperacao,
            };
          });
        const formatted2 = data
          .filter(item =>
            checkAll ? item === item : selectionModel.includes(item.id),
          )
          .map(info => {
            return {
              ...info,
              nomeRazaoPagadorExcel: info.nomePagador,
              documentoFederalExcel: maskCpfCnpj(info.documentoFederal),
              numeroTituloExcel: info.numeroTitulo,
              nomeArquivoBancario: info.nomeArquivoBancario,
              dataPagamentoFormattedExcel:
                info.dataPagamento &&
                moment(info.dataPagamento).format('DD/MM/YYYY'),
              dataVencimentoFormattedExcel:
                info.dataVencimento &&
                moment(info.dataVencimento).format('DD/MM/YYYY'),
              dataPagamentoFormattedExcel:
                info.dataPagamento &&
                moment(info.dataPagamento).format('DD/MM/YYYY'),
              valorPagoExcel: info.valorPago && formatMoney(info.valorPago),
              valorExcel: info.valorTitulo && formatMoney(info.valorTitulo),
              codigoBarrasExcel: info.codigoBarras,
              nossoNumeroExcel: info.nossoNumero,
              dataRegistroFormattedExcel:
                info.dataRegistro &&
                moment(info.dataRegistro).format('DD/MM/YYYY'),
              diferencaDiasFormattedExcel:
                info.dataVencimento &&
                DifferenceBetweenDate(
                  moment(info.dataVencimento).format('DD/MM/YYYY'),
                  moment(Date.now()).format('DD/MM/YYYY'),
                ),
              id: info.id,
              situacaoExcel: info.registrado,
              situacaoTableExcel: info.status.descricao,
              loading: false,
              tipoOperacao: info.tipoOperacao,
            };
          });
        setRowCount(formatted2.length);
        setRowsTable(formatted);
        setCarregandoExcel(false);
        setPodeExcel(true);
        setRowsTableExcel(formatted2);
      } else if (selectionModel.length == 0) {
        const { data } = await api_multipague_cobranca
          .get(
            `cobranca-simples?clienteConta=${contaPadrao.id}&page=${
              rowsState.page
            }${
              situationArray.length !== null
                ? `&situacao=${situationArray.join()}`
                : ''
            }&numeroTitulo=${
              numeroDocumento ? numeroDocumento : ''
            }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
              dataPagamentoDe !== '' && dataPagamentoAte !== ''
                ? 'PAGO'
                : situacao
                ? situacao
                : ''
            }&dataInicial=${
              dataSolicitacaoDe !== ''
                ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataFinal=${
              dataSolicitacaoAte !== ''
                ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoInicial=${
              dataPagamentoDe !== ''
                ? moment(dataPagamentoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoFinal=${
              dataPagamentoAte !== ''
                ? moment(dataPagamentoAte).format('YYYY-MM-DD').trim()
                : ''
            }&cpfCnpj=${codigo.replace(
              /[^\d]/g,
              '',
            )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
            config,
          )
          .catch(error => {
            toastComponentError(error.response.data.mensagem);
          });
        const formatted = data.map(info => {
          return {
            ...info,
            nomeRazaoPagadorExcel: info.nomePagador,
            documentoFederalExcel: maskCpfCnpj(info.documentoFederal),
            numeroTituloExcel: info.numeroTitulo,
            nomeArquivoBancario: info.nomeArquivoBancario,
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            dataVencimentoFormattedExcel:
              info.dataVencimento &&
              moment(info.dataVencimento).format('DD/MM/YYYY'),
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            valorPagoExcel: info.valorPago && formatMoney(info.valorPago),
            valorExcel: info.valorTitulo && formatMoney(info.valorTitulo),
            codigoBarrasExcel: info.codigoBarras,
            nossoNumeroExcel: info.nossoNumero,
            dataRegistroFormattedExcel:
              info.dataRegistro &&
              moment(info.dataRegistro).format('DD/MM/YYYY'),
            diferencaDiasFormattedExcel:
              info.dataVencimento &&
              DifferenceBetweenDate(
                moment(info.dataVencimento).format('DD/MM/YYYY'),
                moment(Date.now()).format('DD/MM/YYYY'),
              ),
            id: info.id,
            situacaoExcel: info.registrado,
            situacaoTableExcel: info.status.descricao,
            loading: false,
            tipoOperacao: info.tipoOperacao,
          };
        });
        setRowsTableExcel(formatted);
        setRowCount(formatted.length);
        setCarregandoExcel(false);
        setPodeExcel(true);
        dispatch(showLoading(false));
        setSelectionModel([]);
      } else {
        dispatch(showLoading(true));
        const { data } = await api_multipague_cobranca_cnab
          .get(
            `cobranca-simples?clienteConta=${contaPadrao.id}&page=${
              rowsState.page
            }&situacao=${regraSituacao}&nomePagador=${descricao}&numeroTitulo=${
              numeroDocumento ? numeroDocumento : ''
            }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
              dataPagamentoDe !== '' && dataPagamentoAte !== ''
                ? 'PAGO'
                : situacao
                ? situacao
                : ''
            }&dataInicial=${
              dataSolicitacaoDe !== ''
                ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataFinal=${
              dataSolicitacaoAte !== ''
                ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoInicial=${
              dataPagamentoDe !== ''
                ? moment(dataPagamentoDe).format('YYYY-MM-DD').trim()
                : ''
            }&dataPagamentoFinal=${
              dataPagamentoAte !== ''
                ? moment(dataPagamentoAte).format('YYYY-MM-DD').trim()
                : ''
            }&cpfCnpj=${codigo.replace(
              /[^\d]/g,
              '',
            )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
            config,
          )
          .catch(error => {
            toastComponentError(error.response.data.mensagem);
          });

        const formatted = data.map(info => {
          return {
            ...info,
            nomeRazaoPagadorExcel: info.nomePagador,
            documentoFederalExcel: maskCpfCnpj(info.documentoFederal),
            numeroTituloExcel: info.numeroTitulo,
            nomeArquivoBancario: info.nomeArquivoBancario,
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            dataVencimentoFormattedExcel:
              info.dataVencimento &&
              moment(info.dataVencimento).format('DD/MM/YYYY'),
            dataPagamentoFormattedExcel:
              info.dataPagamento &&
              moment(info.dataPagamento).format('DD/MM/YYYY'),
            valorPagoExcel: info.valorPago && formatMoney(info.valorPago),
            valorExcel: info.valorTitulo && formatMoney(info.valorTitulo),
            codigoBarrasExcel: info.codigoBarras,
            nossoNumeroExcel: info.nossoNumero,
            dataRegistroFormattedExcel:
              info.dataRegistro &&
              moment(info.dataRegistro).format('DD/MM/YYYY'),
            diferencaDiasFormattedExcel:
              info.dataVencimento &&
              DifferenceBetweenDate(
                moment(info.dataVencimento).format('DD/MM/YYYY'),
                moment(Date.now()).format('DD/MM/YYYY'),
              ),
            id: info.id,
            situacaoExcel: info.registrado,
            situacaoTableExcel: info.status.descricao,
            loading: false,
          };
        });
        setRowsTableExcel(formatted);
        setRowCount(formatted.length);
        setCarregandoExcel(false);
        setPodeExcel(true);
        dispatch(showLoading(false));
        setSelectionModel([]);
      }
    } catch (err) {
      setRowCount(0);
      dispatch(hideLoading(false));
      setCarregandoExcel(false);
    }
  }, [
    contaPadrao,
    dataInicial,
    dataFinal,
    nossoNumero,
    nomeCNAB,
    numeroDocumento,
    vencidosFilter,
    emDiaFilter,
    aVencerFilter,
    situacao,
    infoCnab,
    rowsState,
    selectionModel,
    tipoOperacao,
  ]);

  const changeCheckboxAll = async checked => {
    setCheckAll(checked);
    setCarregandoDadosExcel(true);
    const config = {
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
      },
    };
    if (checked) {
      let situationArray = [];

      if (vencidosFilter) situationArray.push('VENCIDO');
      if (emDiaFilter) situationArray.push('VENCENDO');
      if (aVencerFilter) situationArray.push('A_VENCER');

      const { data } = await api_multipague_cobranca_cnab
        .get(
          `cobranca-simples?clienteConta=${
            contaPadrao.id
          }&situacao=${regraSituacao}&nomePagador=${descricao}&numeroTitulo=${
            numeroDocumento ? numeroDocumento : ''
          }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
            dataPagamentoDe !== '' && dataPagamentoAte !== ''
              ? 'PAGO'
              : situacao
              ? situacao
              : ''
          }&dataInicial=${
            dataSolicitacaoDe !== ''
              ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
              : ''
          }&dataFinal=${
            dataSolicitacaoAte !== ''
              ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
              : ''
          }&cpfCnpj=${codigo.replace(
            /[^\d]/g,
            '',
          )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
          config,
        )
        .catch(error => {
          toastComponentError(error.response.data.mensagem);
          setCarregandoDadosExcel(false);
          setCheckAll(false);
        });

      setCarregandoDadosExcel(false);
      setSelectionModel(() =>
        data.filter(row => row.registrado).map(e => e.id),
      );
      setGridSelection(old => [...old, ...data.filter(row => row.registrado)]);
      submitForm(infoCnab, 0, rowsState.pageSize);
    } else {
      setCarregandoDadosExcel(false);
      setSelectionModel([]);
      setRowsTableExcel([]);
      submitForm(infoCnab, 0, rowsState.pageSize);
    }
  };

  const bodyCancel = {
    codigoClienteConta: contaPadrao?.id,
    codigoMotivo: selectMotivoValue,
    codigoTipoInstrucao: selectInstrucaoValue,
    idsBoletos: selectionModel,
    observacao: observacao,
  };

  const cancelarBoletosEmLote = async () => {
    if (selectionModel.length > 0) {
      dispatch(showLoading(true));
      try {
        api_multipague_cobranca
          .put(`/cobranca-simples/cancelamento-lote`, bodyCancel)
          .then(response => {
            dispatch(showLoading(false));
            toastComponentSuccess('Boletos enviados para cancelamento');
            setOpen(false);
            setTimeout(function () {
              location.reload();
            }, 1000);
          })
          .catch(error => {
            toastComponentError(error.response.data.mensagem);
            dispatch(showLoading(false));
            setOpen(false);
          });
      } catch (error) {
        dispatch(showLoading(false));
        setOpen(false);
      }
    } else {
      dispatch(showLoading(false));
      toastComponentWarn(
        'Selecione 1 ou mais boletos para o cancelamento em lote',
      );
      setOpen(false);
    }
  };

  const solicitarImpressao = async params => {
    const { data } = await api_multipague_cobranca.post(
      `/cobranca-simples/imprimir?clienteConta=${
        contaPadrao.id
      }&situacao=${regraSituacao}&nomePagador=${descricao}&numeroTitulo=${
        numeroDocumento ? numeroDocumento : ''
      }&nossoNumero=${nossoNumero ? nossoNumero : ''}&status=${
        dataPagamentoDe !== '' && dataPagamentoAte !== ''
          ? 'PAGO'
          : situacao
          ? situacao
          : ''
      }&dataInicial=${
        dataSolicitacaoDe !== ''
          ? moment(dataSolicitacaoDe).format('YYYY-MM-DD').trim()
          : ''
      }&dataFinal=${
        dataSolicitacaoAte !== ''
          ? moment(dataSolicitacaoAte).format('YYYY-MM-DD').trim()
          : ''
      }&cpfCnpj=${codigo.replace(
        /[^\d]/g,
        '',
      )}&nomeArquivoBancario=${nomeCNAB}${tipoOperacaoParam}`,
      params,
    );
    return data;
  };

  const solicitarImpressaoSeleciona = async params => {
    const { data } = await api_multipague_cobranca.post(
      `/cobranca-simples/imprimirSelecionados`,
      params,
    );

    return data;
  };

  const imprimirBoletosEmLote = async () => {
    const idsFomattedAll = rowsTable.filter(
      item => item.status.descricao === 'Registrado',
    );
    if (selectionModel.length > 0) {
      dispatch(showLoading(true));
      try {
        await solicitarImpressaoSeleciona({
          codigosBoletos: selectionModel,
          codigoClienteConta: contaPadrao.id,
        });
        toastComponentSuccess(
          'Solicitação de impressão de boletos realizada com sucesso!',
        );
        dispatch(hideLoading(false));
        setSelectionModel([]);
        if (selectionModel.length > 20) {
          toastComponentSuccess('Boletos serão impressos em lotes de 20');
        }
      } catch (error) {}
    } else {
      toastComponentError('Nenhum boleto selecionado!');
      dispatch(hideLoading(false));
      setSelectionModel([]);
    }
  };

  useEffect(() => {
    localStorage.removeItem('data_pagamento_boleto');
  }, []);

  useEffect(() => {
    api_multipague_configuracao
      .get('/motivo?evento=CANCELAR_BOLETO')
      .then(response => {
        const selectFilter = response.data;
        const aquiSelectMotivo = selectFilter.map(key => (
          <MenuItem key={key} value={key.id}>
            {key.descricao}
          </MenuItem>
        ));
        setSelectMotivo(aquiSelectMotivo);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    api_multipague_configuracao
      .get('/tipo-instrucao')
      .then(response => {
        const selectFilter = response.data;
        const aquiSelectInstrucao = selectFilter.map(key => (
          <MenuItem key={key} value={key.id}>
            {key.descricao}
          </MenuItem>
        ));
        setSelectInstrucao(aquiSelectInstrucao);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  const cleanFilters = () => {
    setCarregandoDadosExcel(false);
    setCheckAll(false);
    setSelectionModel([]);
    setCarregandoExcel(false);
    setVencidosFilter(false);
    setEmDiaFilter(false);
    setAVencerFilter(false);
    setRegraSituacao('');
    setSituacaoPadrao('Selecione a situação');
    setStateInput('');
    setDescricao('');
    setNumeroDocumento('');
    setNomeCNAB('');
    setNossoNumero('');
    setSituacao('');
    setDataSolicitacaoDe('');
    setDataSolicitacaoAte('');
    setDataPagamentoDe('');
    setDataPagamentoAte('');
    setCodigo('');
    setTipoOperacao(null);
    setInputValueTipoOperacao('');
    submitForm({}, 0, rowsState.pageSize, cleanFilters);
    setState({
      checkedVencidos: false,
      checkedNodia: false,
      checkedAvencer: false,
    });
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="CPF/CNPJ"
            id="outlined-size-small"
            value={codigo}
            onChange={e => {
              setCodigo(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Nome"
            id="outlined-size-small"
            value={descricao}
            onChange={e => {
              setDescricao(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Vencimento - De:"
            id="outlined-size-small"
            value={dataSolicitacaoDe}
            onChange={handleSolicitacaoDe}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Vencimento - Até:"
            id="outlined-size-small"
            variant="outlined"
            value={dataSolicitacaoAte}
            onChange={handleSolicitacaoAte}
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <Autocomplete
            id="combo-box-demo"
            size="small"
            options={selecionarSituacao}
            inputValue={stateInput}
            onInputChange={(event, newValue) => {
              setStateInput(newValue);
            }}
            getOptionLabel={option => option.descricao}
            onChange={(event, option) => {
              if (option) {
                setSituacao(option.id);
              } else {
                setSituacao('');
              }
            }}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Status"
                placeholder={situacaoPadrao}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <Autocomplete
            value={tipoOperacao}
            onChange={(event, option) => {
              if (option) {
                setTipoOperacao(option.id);
              } else {
                setTipoOperacao('');
              }
            }}
            getOptionLabel={option => option.descricao}
            size="small"
            inputValue={inputValueTipoOperacao}
            onInputChange={(event, newInputValue) => {
              setInputValueTipoOperacao(newInputValue);
            }}
            id="combo-box-demo"
            options={[
              {
                id: 'COBRANCA_SIMPLES',
                descricao: 'Simples',
              },
              {
                id: 'OPERACAO_GARANTIA',
                descricao: 'Garantia',
              },
            ]}
            renderInput={params => (
              <TextField
                {...params}
                label="Tipo Operação"
                placeholder="Selecione o tipo de operação"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            )}
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Pagamento - De:"
            id="outlined-size-small"
            value={dataPagamentoDe}
            onChange={handlePagamentoDe}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Data Pagamento - Até:"
            id="outlined-size-small"
            value={dataPagamentoAte}
            onChange={handlePagamentoAte}
            variant="outlined"
            type="date"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Nosso Número"
            id="outlined-size-small"
            value={nossoNumero}
            onChange={e => {
              setNossoNumero(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Número Documento"
            id="outlined-size-small"
            value={numeroDocumento}
            onChange={e => {
              setNumeroDocumento(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 2, md: 2 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CNAB"
            id="outlined-size-small"
            value={nomeCNAB}
            onChange={e => {
              setNomeCNAB(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 3, md: 3 },
      },
      {
        html: () => (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedVencidos}
                  onChange={handleChangeVencidos}
                  name="checkedVencidos"
                />
              }
              label="Vencidos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedNodia}
                  onChange={handleChangeNodia}
                  name="checkedNodia"
                  color="primary"
                />
              }
              label="No dia"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedAvencer}
                  onChange={handleChangeAvencer}
                  name="checkedAvencer"
                  color="primary"
                />
              }
              label="A vencer"
            />
          </FormGroup>
        ),
        grid: { xs: 12, sm: 10, md: 10 },
      },
    ],
    searchButton: {
      searchButton: () => handleSearch(),
    },
    cleanButton: {
      cleanButton: () => cleanFilters(),
    },
  };

  return (
    <>
      <DataGridPage
        children={
          <Dialog
            onClose={handleClose}
            style={{ borderRadius: '20px' }}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogTitle id="simple-dialog-title">
              Justifique o cancelamento em lote
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Tipo de Controle
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullwidth
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={selectInstrucaoValue}
                    onChange={e => setSelectInstrucaoValue(e.target.value)}
                  >
                    {selectInstrucao}
                  </Select>
                </FormControl>
              </div>
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Motivo
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullwidth
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={selectMotivoValue}
                    onChange={e => setSelectMotivoValue(e.target.value)}
                  >
                    {selectMotivo}
                  </Select>
                </FormControl>
              </div>
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Observação
                </InputLabel>
                <TextField
                  multiline
                  fullWidth
                  variant="outlined"
                  rows={6}
                  value={observacao}
                  onChange={e => setObservacao(e.target.value)}
                />
              </div>

              <Chip
                variant="contained"
                color="primary"
                width="115"
                onClick={cancelarBoletosEmLote}
                label="Confirmar"
              />
            </DialogContent>
          </Dialog>
        }
        backButton
        title="Consultar Boleto"
        crumb={[
          {
            name: 'Minhas Contas',
            link: '/MinhasContas',
          },
          {
            name: 'Cobrança Simples',
            link: '/Buscar/Boleto',
          },
        ]}
        newRegister={() => history.push('/Cobranca/Simples')}
        headerComponent={() => {
          return (
            <Grid container>
              <Grid item xs={12} md={6}>
                <Chip
                  style={{ minWidth: '180px' }}
                  label={
                    formattedMoney(totalizando)
                      ? 'Total selecionado: ' + formattedMoney(totalizando)
                      : 'Total selecionado: 0'
                  }
                  color="primary"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                md={6}
              >
                <Chip
                  color="secondary"
                  variant="outlined"
                  startIcon={<MdPrint />}
                  label="Cancelar em Lote"
                  style={{ width: '180px', marginLeft: '20px' }}
                  onClick={handleClickOpen}
                  disabled={
                    rowsTable.length > 0
                      ? rowsTable
                          .filter(item => selectionModel.includes(item.id))
                          .some(row => row.status.valor?.includes('PAGO'))
                      : rowsTableExcel
                          .filter(item => selectionModel.includes(item.id))
                          .some(row => row.status.valor?.includes('PAGO'))
                  }
                />
                <Chip
                  color="primary"
                  variant="outlined"
                  startIcon={<MdPrint />}
                  style={{ width: '180px', marginLeft: '20px' }}
                  label="Boletos em Lote"
                  onClick={imprimirBoletosEmLote}
                />
                {!podeExcel ? (
                  <Chip
                    color="primary"
                    variant="outlined"
                    style={{ width: '180px', marginLeft: '20px' }}
                    startIcon={<FaRegFileExcel />}
                    onClick={solicitarExcel}
                    label={
                      !carregandoExcel ? 'Solicitar Excel' : 'Carregando...'
                    }
                  />
                ) : (
                  <ExcelFile
                    element={
                      <Chip
                        variant="outlined"
                        style={{ width: '180px', marginLeft: '20px' }}
                        color="primary"
                        startIcon={<FaRegFileExcel />}
                        label="Exportar Excel"
                      />
                    }
                  >
                    {filtrouExcel ? (
                      <ExcelSheet data={rowsTableExcel} name="Boletos">
                        <ExcelColumn
                          label="Sacado"
                          value="nomeRazaoPagadorExcel"
                        />
                        <ExcelColumn
                          label="CPF/CNPJ"
                          value="documentoFederalExcel"
                        />
                        <ExcelColumn label="CNAB" value="nomeArquivoBancario" />
                        <ExcelColumn
                          label="Nº Boleto"
                          value="numeroTituloExcel"
                        />
                        <ExcelColumn label="Valor" value="valorExcel" />
                        <ExcelColumn
                          label="Vencimento"
                          value="dataVencimentoFormattedExcel"
                        />
                        <ExcelColumn
                          label="Pagamento"
                          value="dataPagamentoFormattedExcel"
                        />
                        <ExcelColumn
                          label="Dif Dias"
                          value="diferencaDiasFormattedExcel"
                        />
                        <ExcelColumn
                          label="Tipo Operação"
                          value="tipoOperacao"
                        />
                        <ExcelColumn
                          label="Status"
                          value="situacaoTableExcel"
                        />
                      </ExcelSheet>
                    ) : (
                      <ExcelSheet data={rowsTableExcel} name="Boletos">
                        <ExcelColumn
                          label="Sacado"
                          value="nomeRazaoPagadorExcel"
                        />
                        <ExcelColumn
                          label="CPF/CNPJ"
                          value="documentoFederalExcel"
                        />
                        <ExcelColumn label="CNAB" value="nomeArquivoBancario" />
                        <ExcelColumn
                          label="Nº Boleto"
                          value="numeroTituloExcel"
                        />
                        <ExcelColumn label="Valor" value="valorExcel" />
                        <ExcelColumn
                          label="Vencimento"
                          value="dataVencimentoFormattedExcel"
                        />
                        <ExcelColumn
                          label="Pagamento"
                          value="dataPagamentoFormattedExcel"
                        />
                        <ExcelColumn
                          label="Dif Dias"
                          value="diferencaDiasFormattedExcel"
                        />
                        <ExcelColumn
                          label="Tipo Operação"
                          value="tipoOperacao"
                        />
                        <ExcelColumn
                          label="Status"
                          value="situacaoTableExcel"
                        />
                      </ExcelSheet>
                    )}
                  </ExcelFile>
                )}
              </Grid>
            </Grid>
          );
        }}
        formData={filterInputs}
        rows={filtrouExcel ? rowsTableExcel : rowsTable}
        columns={filtrouExcel ? columnsTableExcel : columnsTable}
        checkboxSelection
        loading={loadingTable}
        minHeight="600px"
        onSelectionModelChange={newSelection => {
          const ids = Object.values(newSelection);
          const newIds = ids.filter(id => !selectionModel.includes(id));
          setSelectionModel(old => [...old, ...newIds]);
        }}
        onRowClick={params => {
          setRowId(params.id);
          setGridSelection(old => [...old, params.row]);
          if (!params.row.registrado) {
            setTimeout(() => {
              setSelectionModel(old => old.filter(item => item !== params.id));
            }, 200);
          }
        }}
        onPageSizeChangeFunction={newPageSize => handlePageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50]}
        selectionModel={selectionModel}
        textNoRows={textNoRows}
        rowCount={rowCount}
        {...rowsState}
        onPageChangeFunction={page =>
          submitForm(infoCnab, page, rowsState.pageSize)
        }
      />
    </>
  );
};
export default HomeCnab;
