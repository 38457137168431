
export const TIPO_CHAVEPIX = [
  'CNPJ',
  'CPF',
  'Telefone',
  'E-mail',
  'Chave Aleatória',
  'Favorecido',
];

export const INPUT_CHAVEPIX_TIPO = {
  CPF: '999.999.999-99',
  Telefone: '(99) 99999-9999',
  CNPJ: '99.999.999/9999-99',
  'E-mail': 'E-mail',
  'Chave Aleatória': 'Chave Aleatória',
};

export const initialStateSearchKey = {
  sucesso: false,
  mensagem: null,
  chave: '',
  tipoChave: 0,
  nomeCorrentista: '',
  nomeFantasia: '',
  tipoPessoa: 1,
  documentoFederal: '',
  ticket: '',
  conta: {
    conta: '',
    tipoConta: 0,
    agencia: '',
    ispb: null,
  },
  banco: {
    descricao: '',
    numero: '',
    ispb: '',
  },
};

